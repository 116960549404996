@import '../../theme/scss/utilities.scss';

.container {
  background-color: $auth-background;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.authentication {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 180px;
}
