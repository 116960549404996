.register {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 8px;

  &-title {
    font-size: 1.5rem;
    text-align: center;
  }
}

.register-inner {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.name {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
.btn {
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
}
