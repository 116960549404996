.list {
  .nav-link {
    text-decoration: none;
    color: inherit;

    &:not(:nth-child(1)) {
      div {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}
