@import '../../theme/scss/utilities.scss';

.login {
  background-color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;

  .link {
    text-decoration: none;
    transition: color 0.2s;
    &:hover {
      color: $main;
    }
  }
}
